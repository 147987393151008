// This file can be replaced during build by using the `fileReplacements` array.
// `ng build -c prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { commonFeatureFlags, CommonFeatureFlags } from "../../../common/constants/CommonFeatureFlags";
import { commonEnvironment } from "./environment.common";

export const environment = {
    ...commonEnvironment,
    production: false,
    environment: "uat",
    redirectUrl: "https://jst.uat.findex.solutions",
    backendUrl: "https://47ef4cag23.execute-api.ap-southeast-2.amazonaws.com/uat",
    locationsUrl: "https://uat.location.findex.dev",
    loggingLevel: commonEnvironment.loggingLevels.info,
    notification: {
        endpoint: "wss://kdvb8poe18.execute-api.ap-southeast-2.amazonaws.com/uat",
        channel: "uat",
    },
    sentry: {
        ...commonEnvironment.sentry,
        enabled: true,
    },
    documentServiceUrl: "https://132444uak4.execute-api.ap-southeast-2.amazonaws.com/uat",
    emailPreviewEndpoint: "https://460ivdpaya.execute-api.ap-southeast-2.amazonaws.com/uat/email/preview",
    azureAD: {
        ...commonEnvironment.azureAD,
        clientID: "7eb3893a-1d84-4189-862f-cdb3594a066b",
    },
    taskx: "https://uat.taskx.findex.dev",
    crm: "https://uatfull-familyoffice.cs152.force.com/s/",
    googleAnalytics: {
        id: "UA-114939185-12",
    },
    hotjar: {
        id: "2488553",
    },
    constants: {
        DEBOUNCE_TIME_MS: 500,
        WS_RECONNECT_INTERVAL: 2000,
        SALESFORCE_LINK_PREFIX: "https://uatfull-familyoffice.cs152.force.com/s/opportunity/",
        SALESFORCE_LINK_SUFFIX: "",
    },
    featureFlags: {
        [CommonFeatureFlags.YEARLESS_SCOPES]: commonFeatureFlags.uat.yearlessScopes,
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import "zone.js/plugins/zone-error"; // Included with Angular CLI.
